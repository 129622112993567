import { GraphQLClient } from 'graphql-request'
import { createClient as createWebsocketClient } from 'graphql-ws'

const apiUrl =
  process.env.GATSBY_API_URL || 'http://localhost.clockhosting.com:7668'

let httpClient // used as singleton for non websocket clients

/**
 * Singleton GraphQL client using HTTP
 *
 * @return {GraphQLClient}
 */
function getHttpClient() {
  if (!httpClient) {
    httpClient = new GraphQLClient(`${apiUrl}/graphql`, {
      credentials: 'include'
    })
  }

  return httpClient
}

let wsClient // used as singleton for websocket clients

/**
 * Singleton GraphQL subscription client using WebSocket
 *
 * This allows us to use the same client for all subscriptions rather than
 * creating a new websocket connection for each subscription.
 *
 * @return {import('graphql-ws').Client}
 */
function getSubscriptionClient() {
  if (!wsClient) {
    const url = apiUrl.replace('http', 'ws') + '/graphql'
    wsClient = createWebsocketClient({ url, lazy: true })
  }

  return wsClient
}

export { getHttpClient, getSubscriptionClient }
